import React from 'react';
import { authMenu } from '../menu';
import Footer from '../layout/Footer/Footer';

const footers = [
	{ path: authMenu.login.path, element: null, exact: true },
	{ path: authMenu.confirmSignUp.path, element: null, exact: true },
	{ path: authMenu.forgetGroup.subMenu.forgetPassword.path, element: null, exact: true },
	{ path: authMenu.forgetGroup.subMenu.confirmation.path, element: null, exact: true },
	{ path: authMenu.forgetGroup.subMenu.resetPassword.path, element: null, exact: true },
	{ path: '*', element: <Footer /> },
];

export default footers;
